import axios from "axios";
import { configure as configureAxiosHooks } from "axios-hooks";
import { logOut } from "store/slicers/auth/auth";
import store from "store/store";

const apiEndpoint =
  process.env.NODE_ENV === "production" ? "https://api.assistcam.com.tr/api/" : "http://assistcam.local:81/api/";

const api = axios.create({
  baseURL: apiEndpoint,
});

api.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken !== null) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && 401 === error.response.status) {
      store.dispatch(logOut({}));
    } else {
      return Promise.reject(error);
    }
  }
);

configureAxiosHooks({
  axios: api,
  cache: false,
});

export { apiEndpoint };
export default api;
